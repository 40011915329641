body {
	margin-bottom: 0 !important;
	color: #a6adbb;
}

button:disabled {
	color: #686866 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: #fff;
	-webkit-box-shadow: 0 0 0px 1000px transparent inset;
	box-shadow: 0 0 0px 1000px transparent inset;
	transition: background-color 5000s ease-in-out 0s;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
	-webkit-text-fill-color: #fff;
	-webkit-box-shadow: 0 0 0px 1000px transparent inset;
	box-shadow: 0 0 0px 1000px transparent inset;
	transition: background-color 5000s ease-in-out 0s;
}

input.Mui-disabled:disabled {
	-webkit-text-fill-color: #686866 !important;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(119, 33, 33, 0.3);
	border-radius: 10px;
	background-color: #f5f5f534;
}

::-webkit-scrollbar {
	width: 8px;
	background-color: #1d232a;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #7480ffd6;
}

/********************* 공통 UI **********************/
.title {
	color: #fff;
	font-size: 1rem;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}

/********************* 성별 pick UI **********************/
.radio-pick-gender [type='radio'] {
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
}

.radio-pick-gender [type='radio'] + svg {
	cursor: pointer;
}

/* CHECKED STYLES */
.radio-pick-gender [type='radio']:checked + svg {
	outline: 2px solid #7480ff;
	border-radius: 50%;
}

/********************* 연령대 pick UI **********************/
.radio-pick-age [type='radio'] {
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
}

.radio-pick-age [type='radio'] {
	cursor: pointer;
}

/* CHECKED STYLES */
.radio-pick-age label {
	position: relative;
}

.radio-pick-age label:has([type='radio']:checked) {
	outline: 2px solid #7480ff;
	color: #7480ff;
}

.radio-pick-age label:has([type='radio']:checked)::after {
	content: '';
	background-image: url('../asset/icons/check-box.svg');
	width: 24px;
	height: 24px;
	position: absolute;
	right: 16px;
	bottom: 13px;
}

/********************* 종목 pick UI **********************/
.radio-pick-sports [type='radio'] {
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
}

.radio-pick-sports [type='radio'] {
	cursor: pointer;
}

/* CHECKED STYLES */
.radio-pick-sports label {
	position: relative;
}

.radio-pick-sports label:has([type='radio']:checked) {
	outline: 2px solid #7480ff;
	color: #7480ff;

	img {
		filter: invert(15%) sepia(84%) saturate(2670%) hue-rotate(231deg) brightness(90%) contrast(90%);
	}
}

.radio-pick-sports label:has([type='radio']:checked)::after {
	content: '';
	background-image: url('../asset/icons/check-box.svg');
	width: 24px;
	height: 24px;
	position: absolute;
	right: 16px;
	top: 13px;
}

.sport-0 {
	width: 50px;
}
.sport-1 {
	width: 55px;
}
.sport-2 {
	width: 55px;
}

/****************** 체육관 설정 ************/
.custom-select::after {
	content: '';
	background-image: url('../asset/icons/arrow-select.svg');
	width: 24px;
	height: 24px;
}

.map-container {
	color: #000;
	display: flex;
	position: relative;
	z-index: 9;

	#search-result {
		width: 40%;
		background-color: #000000a6;
		position: absolute;
		z-index: 10;
		height: 100%;
		display: flex;
		flex-direction: column;

		.scroll-wrapper {
			color: #fff;

			ul#places-list {
				height: 300px;
				overflow-y: scroll;
				height: 260px;
				> li {
					padding: 6px;
					cursor: pointer;

					.marker {
						margin-right: 2px;
					}
					a > .info-item {
						&:not(:last-child) {
							margin-bottom: 2px;
						}
						&&.place-name {
							font-size: var(--fs-2);
						}
					}
				}
			}
		}

		#pagination {
			display: flex;
			justify-content: center;
			color: #fff;
			margin-top: auto;
			> a {
				margin: 0px 4px;

				&&.on {
					color: #7480ff;
				}
			}
		}
	}

	#map {
		width: 100%;
		height: 300px;
	}
}

.info {
	display: flex;
}

.result-text {
	color: #fff;
}

/********** 채팅방 **********/
.chat {
	padding-top: 1rem !important;
}
